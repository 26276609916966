<script>
import i18n from "../i18n";
import * as moment from "moment";
import {
  SET_USER_LANGUAGE,
  SET_SANDBOX_MODE_ALERT,
  UPDATE_MERCHANT_ENTITY_DETAILS,
  FETCH_NOTIFICATIONS,
  MARK_NOTIFICATION_READ,
  READ_ALL_NOTIFICATIONS,
} from "@/state/actions.type";
import SwitchInput from './switch-input.vue';
export const CHAIPAY_APP_URL = "https://www.docs.portone.cloud/";
export default {
  components: {
    SwitchInput
  },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/eng.png"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/vietnam_rounded.png"),
          language: "vn",
          title: "Vietnam"
        },
        {
          flag: require("@/assets/images/flags/thailand.jpeg"),
          language: "th",
          title: "Thailand"
        },
        {
          flag: require("@/assets/images/flags/korea_rounded.jpg"),
          language: "kr",
          title: "Korean"
        },
        // {
        //   flag: require("@/assets/images/flags/Kenya.png"),
        //   language: "ke",
        //   title: "Kenya"
        // },
        // {
        //   flag: require("@/assets/images/flags/south_africa.png"),
        //   language: "za",
        //   title: "South Africa"
        // },
        // {
        //   flag: require("@/assets/images/flags/nigeria.png"),
        //   language: "ng",
        //   title: "Nigeria"
        // },
        // {
        //   flag: require("@/assets/images/flags/Kenya.png"),
        //   language: "ke",
        //   title: "Kenya"
        // },
        // {
        //   flag: require("@/assets/images/flags/south_africa.png"),
        //   language: "za",
        //   title: "South Africa"
        // },
        // {
        //   flag: require("@/assets/images/flags/nigeria.png"),
        //   language: "ng",
        //   title: "Nigeria"
        // },
        {
          flag: require("@/assets/images/flags/indonesia.png"),
          language: "id",
          title: "Indonesian"
        },
        // {
        //   flag: require("@/assets/images/flags/japan.jpg"),
        //   language: "ja",
        //   title: "Japanese"
        // },
        // {
        //   flag: require("@/assets/images/flags/philippines.jpg"),
        //   language: "fi",
        //   title: "Philippines"
        // },
        // {
        //   flag: require("@/assets/images/flags/malaysia.svg"),
        //   language: "mly",
        //   title: "Malay"
        // },
        // {
        //   flag: require("@/assets/images/flags/french.jpg"),
        //   language: "fr",
        //   title: "French"
        // },
        // {
        //   flag: require("@/assets/images/flags/spain.jpg"),
        //   language: "es",
        //   title: "spanish"
        // },
        // {
        //   flag: require("@/assets/images/flags/chaina.png"),
        //   language: "zh",
        //   title: "Chinese"
        // },
        // {
        //   flag: require("@/assets/images/flags/arabic.png"),
        //   language: "ar",
        //   title: "Arabic"
        // }
      ],
      current_language: null,
      docs_link: CHAIPAY_APP_URL,
      polling: null,
      page: 1,
      pagesize: 10,
      showAllNotifications: true,
      showNotifications: false,
      fetchingNotifications: false,
      liveMode: this.$store.state.auth.currentUser.is_live_mode
    };
  },
  computed: {
    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },
    isAdminUser() {
      return this.userRoles.includes('admin') || this.userRoles.includes('sub-admin')
    },
    unreadCounts() {
      if(this.$store.state.notifications.content.length > 0) {
        return this.$store.state.notifications.content.filter(notification => !notification.read).length
      }
      return 0;
    },
    isOnboardingCompleted() {
      return this.$store.state.auth.currentUser.is_onboarding_completed;
    },
    notifications() {
      return this.$store.state.notifications.content;
    },
    allNotificationsCount() {
      return this.$store.state.notifications.content.length;
    },
    totalNotifications() {
      return this.$store.state.notifications.totalElements;
    },

  },
  filters: {
    date(date) {
      return moment(date).format("MMM DD, YYYY HH:mm:ss");
    }
  },
  created() {
    let lang = this.languages.find(el => el.language === this.$store.state.auth.language);
    this.current_language = lang || this.languages[0];
    i18n.locale = this.current_language.language;
    if(this.userRoles.includes('admin') || this.userRoles.includes('sub-admin')) {
      this.fetchNotifications();
  
      this.polling = setInterval(() => {
        this.fetchNotifications();
      }, 300000);
    }
  },
  watch: {
    liveMode() {
      if(this.isAdminUser) {
        this.$store.dispatch(`merchant/${UPDATE_MERCHANT_ENTITY_DETAILS}`, { is_live_mode: this.liveMode }).then(() => {
          this.hideSandBoxModeAlert(this.liveMode);
        })
        .catch(error => {
          this.$notify({
            type: "error",
            text: error.Message,
            closeOnClick: true,
          })
        })
        .finally(() => window.location.reload());
      }
    },
  },
  methods: {
    fetchNotifications(reset = false) {
      this.fetchingNotifications = true;

      let payload = {
        page: this.page,
        pagesize: this.pagesize,
        filters: {},
        showNotifications: this.showNotifications,
        reset: reset
      };

      if(!this.showAllNotifications) {
        payload.filters.unread_only = true;
      }

      this.$store.dispatch(`notifications/${FETCH_NOTIFICATIONS}`, payload).finally(() => this.fetchingNotifications = false);
    },
    readAllNotification() {
      if(this.unreadCounts > 0) {
        this.page = 1;
        this.$store.dispatch(`notifications/${READ_ALL_NOTIFICATIONS}`)
        .then(() => this.fetchNotifications(true));
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    setLanguage(locale) {
      i18n.locale = locale.language;
      this.current_language = locale;
      this.$store.dispatch(`auth/${SET_USER_LANGUAGE}`, this.current_language.language);
    },
    hideSandBoxModeAlert(value) {
      this.$store.dispatch(`auth/${SET_SANDBOX_MODE_ALERT}`, value);
    },
    async openTransactionDetails(item) {
      if(!item.read) {
        await this.$store.dispatch(`notifications/${MARK_NOTIFICATION_READ}`, item.uuid).then(() => {
          this.fetchNotifications()
        });
      }
      this.$router.push(`/transactions/payments/payment-details?order_ref=${item.metadata.portone_order_ref}`);
    },
    loadMoreNotifications(el) {
      if((Math.ceil(el.srcElement.offsetHeight) + Math.ceil(el.srcElement.scrollTop)) >= el.srcElement.scrollHeight) {
          if(this.$store.getters['notifications/moreNotificationsAvailable']) {
            this.page++;
            this.fetchNotifications();
          }
        }
    },
    toggleNotifications() {
      console.log('in toggle notifications');
      this.showNotifications = !this.showNotifications;
      if(!this.showNotifications) {
        this.page = 1;
        this.fetchNotifications(true);
        this.polling = setInterval(() => {
          this.fetchNotifications();
        }, 300000);
      } else {
        clearInterval(this.polling)
        this.fetchNotifications(true);
      }
    },
    toggleShowAll(value) {
      if(this.showAllNotifications !== value) {
        this.showAllNotifications = value;
        this.page = 1;
        this.fetchNotifications(true);
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  directives: {
    'click-outside': {
      bind (el, binding, vnode) {
        const handleOutsideClick = (e) => {
          e.stopPropagation()
          const { handler, exclude } = binding.value
          const excludedEl = vnode.context.$refs[exclude]

          let clickedOnExcludedEl = !excludedEl || excludedEl.contains(e.target)

          if (!el.contains(e.target) && !clickedOnExcludedEl && vnode.context.showNotifications) {
            vnode.context[handler]();
          }
        }
        document.addEventListener('click', handleOutsideClick)
        document.addEventListener('touchstart', handleOutsideClick)
      },
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <!-- Toggle Sidebar -->
    <!-- <button
      @click="toggleMenu"
      type="button"
      class="btn btn-sm px-3 font-size-24 header-item waves-effect"
      id="vertical-menu-btn"
    >
      <i class="ri-menu-2-line align-middle"></i>
    </button> -->
    <div class="">
      <div class="">
        <div class="navbar-header">
          <b-btn variant="white" style="position: relative;top: 2px;" class="toggle-sidebar p-0" @click="toggleMenu">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M7.81 2H16.19C19.83 2 22 4.17 22 7.81V16.18C22 19.83 19.83 22 16.19 22H7.82C4.18 22 2.01 19.83 2.01 16.19V7.81C2 4.17 4.17 2 7.81 2ZM10.21 15C9.92 15.29 9.92 15.77 10.21 16.06C10.36 16.21 10.55 16.28 10.74 16.28C10.93 16.28 11.12 16.21 11.27 16.06L14.8 12.53C14.9395 12.3889 15.0177 12.1984 15.0177 12C15.0177 11.8016 14.9395 11.6111 14.8 11.47L11.27 7.94C10.98 7.65 10.5 7.65 10.21 7.94C9.92 8.23 9.92 8.71 10.21 9L13.21 12L10.21 15Z" fill="black"/>
            </svg>
          </b-btn>
          <!-- Mega Menu -->
          <!-- <div class="d-flex">
            <b-dropdown
              variant="black"
              class="dropdown-mega d-none d-lg-block ml-2"
              toggle-class="header-item"
              menu-class="dropdown-megamenu"
            >
              <template v-slot:button-content>
                {{ $t('navbar.dropdown.megamenu.text') }}
                <i class="mdi mdi-chevron-down"></i>
              </template>
              <div class="row">
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-md-4">
                      <h5 class="font-size-14 mt-0">{{ $t('navbar.dropdown.megamenu.developers.title') }}</h5>
                      <ul class="list-unstyled megamenu-list">
                        <li>
                          <a
                            :href=this.docs_link target="_blank"
                          >{{ $t('navbar.dropdown.megamenu.developers.list.iguide') }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="row">
                    <div class="col-sm-5">
                      <div>
                        <img
                          src="@/assets/images/megamenu-img.png"
                          alt
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-dropdown>
          </div> -->
          <!-- Mega Menu Ends -->
          <div>
            <p class="page-title">{{ $route.name }}</p>
            <p class="page-title-details mb-0">Hello, {{ $store.state.auth.currentUser.name }}!</p>
            <!-- Look at the  high-level summary of your account here -->
          </div>
          <div class="d-flex ml-auto flex-wrap" style="gap: 8px;">
            <!-- all notifications -->
            <div
              v-click-outside="{
                exclude: 'notification-button',
                handler: 'toggleNotifications'
              }"
            >
              <b-card
                v-if="showNotifications"
                style="top: 90px; right: 25px; z-index: 5; width: 394px;"
                class="position-absolute float-right bg-white"
                body-class="p-0"
              >
              <div>
                <header class="p-3">
                  <label class="h5">Notifications</label>
                  <span class="link-text orange-theme-text float-right" @click="readAllNotification">
                    Mark all as read
                  </span>
                </header>
      
                <div class="border-bottom pb-2">
                  <span class="px-3" :class="showAllNotifications ? 'font-weight-bolder' : 'font-weight-lighter'" @click="toggleShowAll(true)" type="button">
                    All
                  </span>
                  <span class="px-3" :class="!showAllNotifications ? 'font-weight-bolder' : 'font-weight-lighter'" @click="toggleShowAll(false)" type="button">
                    New
                  </span>
                </div>
                <div class="d-flex" style="overflow-y: scroll; height: 50vh;" @scroll="loadMoreNotifications">
                  <div v-if="notifications.length > 0" class="d-flex" style="flex-direction: column;">
                    <div v-for="(notification, index) in notifications" :key="index" :class="{'unread-notification': !notification.read, 'border-bottom': index !== (notifications.length - 1)}">
                      <div class="p-3">
                        <b-card-text class="notification-date mb-2">{{ notification.created_at | date }}</b-card-text>
                        <b-card-text @click="openTransactionDetails(notification)"  style="cursor: pointer;" class="mb-0">
                          {{ notification.metadata.message ? notification.metadata.message : notification.metadata.merchant_order_ref }}
                        </b-card-text>
                        <!-- <div class="d-flex">
                          <b-card-text class="link-text orange-theme-text">Learn more <i class="mdi mdi-arrow-right"></i></b-card-text>
                        </div> -->
                      </div>
                    </div>
                    <div class="d-flex justify-content-center" v-if="fetchingNotifications" >
                      <b-spinner label="Loading..."></b-spinner>
                    </div>
                    <div class="p-3 pb-4 my-auto" v-show="!$store.getters['notifications/moreNotificationsAvailable']">
                      <b-card-text class="orange-theme-text text-center h6">That's all your notifications from the last 30 days.</b-card-text>
                    </div>
                  </div>
                  <div class="m-auto" v-else>
                    <b-card-text class="orange-theme-text text-center h6">You have no notifications</b-card-text>
                  </div>
                </div>
              </div>
              </b-card>
            </div>
            <!-- sandbox and live mode toggle switch -->
            <div class="sandbox-live-mode d-flex justify-content-between px-3 my-auto">
                <p class="font-14px secondary-text my-auto" style="padding-right: 8px;">{{ liveMode ? 'Live Mode' : 'Test Mode' }}</p>
                <switch-input class="my-auto" :disableToggle="!isAdminUser || !isOnboardingCompleted" v-model="liveMode" :value="liveMode" />
            </div>

            <!-- Language Settigns -->
            <b-dropdown variant="white" toggle-class="language" menu-class="drop-menu" class="my-auto">
              <template v-slot:button-content>
                <b-img :src="current_language.flag" alt="Language" height="24" width="24" rounded="circle"></b-img>
                <span style="color: rgba(0, 0, 0, 0.50);"> {{ current_language.language.toUpperCase() }}</span>
                <img src="@/assets/images/down.svg" alt="down" height="18" width="18" />
              </template>

              <b-dropdown-item
                class="language-menu-item"
                v-for="(entry, i) in languages"
                :key="`Lang${i}`"
                @click="setLanguage(entry)"
                :link-class="{'active': entry.language === current_language.language, 'language-menu-item d-flex': true}"
              >
                <div class="my-auto">
                  <b-img :src="entry.flag" v-if="entry.language == 'kr'" alt="Language" height="27" width="27" rounded="circle"></b-img>
                  <b-img :src="entry.flag" v-else alt="Language" height="24" width="24" rounded="circle"></b-img>
                  {{ entry.title }}
                </div>
              </b-dropdown-item>
            </b-dropdown>

            <!-- notifications toggle -->
            <div v-if="userRoles.includes('admin') || userRoles.includes('sub-admin')" style="position: relative;" class="d-flex cursor-pointer" ref="notification-button" @click="toggleNotifications">
              <div class="header-notification my-auto d-flex">
                <img src="@/assets/images/notification-bing.svg" height="20" width="20" class="mx-auto my-auto" alt="notifications">
              </div>
              <svg v-if="unreadCounts > 0" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19 18" fill="none" style="position: absolute;right: -3px;top: -3px;">
                <circle cx="9.14502" cy="9" r="9" fill="#FC6B2D"/>
              </svg>
            </div>

            <div style="width: 1px;height: 44px;position: relative;background: #DCDAD5;"></div>
            <!-- Profile Menu -->
            <b-dropdown
              right
              variant="white"
              toggle-class="profile"
              menu-class="drop-menu profile-menu"
            >
              <template v-slot:button-content>
                <div class="avtar d-flex">
                  <img
                    src="@/assets/images/avtar.svg"
                    alt="Header Avatar"
                    class="my-auto mx-auto"
                  />
                </div>
                <img src="@/assets/images/down.svg" alt="down" height="18" width="18" />
              </template>

              <div class="profile-main mx-auto">
                <div class="avtar d-flex">
                  <img
                    src="@/assets/images/avtar.svg"
                    alt="Header Avatar"
                    class="my-auto mx-auto"
                  />
                </div>

                <div class="profile-details">
                  <p class="m-0 font-16px" style="line-height: 19px;">{{$store.state.auth.currentUser.brand_name}}</p>
                  <p class="m-0 font-12px text-color-secondary" style="line-height: 14px;">{{$store.state.auth.currentUser.email_address}}</p>
                  <p class="m-0 font-12px text-chai" v-if="$store.state.auth.currentUser.is_master_merchant" style="line-height: 14px;">Master Merchant</p>
                </div>
              </div>

              <routerLink class="dropdown-item profile-menu-item d-flex" to="/profile">
                <div class="my-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M20.5906 22C20.5906 18.13 16.7406 15 12.0006 15C7.26064 15 3.41064 18.13 3.41064 22M12.0006 12C13.3267 12 14.5985 11.4732 15.5362 10.5355C16.4739 9.59785 17.0006 8.32608 17.0006 7C17.0006 5.67392 16.4739 4.40215 15.5362 3.46447C14.5985 2.52678 13.3267 2 12.0006 2C10.6746 2 9.40279 2.52678 8.46511 3.46447C7.52743 4.40215 7.00064 5.67392 7.00064 7C7.00064 8.32608 7.52743 9.59785 8.46511 10.5355C9.40279 11.4732 10.6746 12 12.0006 12Z" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  {{ $t('navbar.dropdown.account-user.list.profile') }}
                </div>
              </routerLink>

              <routerLink  :class="{ 'disable-side-bar': !isOnboardingCompleted }" class="dropdown-item profile-menu-item d-flex" :to="{ name: 'General Settings', query: { tab: 'Team' } }" v-if="userRoles.includes('admin') || userRoles.includes('sub-admin')">
                <div class="my-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M16.4107 4.00023C18.3507 4.00023 19.9107 5.57023 19.9107 7.50023C19.9107 9.39023 18.4107 10.9302 16.5407 11.0002C16.4543 10.9902 16.3671 10.9902 16.2807 11.0002M18.3407 20.0002C19.0607 19.8502 19.7407 19.5602 20.3007 19.1302C21.8607 17.9602 21.8607 16.0302 20.3007 14.8602C19.7507 14.4402 19.0807 14.1602 18.3707 14.0002M9.1607 10.8702C9.0607 10.8602 8.9407 10.8602 8.8307 10.8702C7.68291 10.8313 6.59535 10.3471 5.79833 9.52019C5.00132 8.69332 4.55744 7.58869 4.5607 6.44023C4.5607 3.99023 6.5407 2.00023 9.0007 2.00023C10.1769 1.97902 11.3134 2.42593 12.1602 3.24265C13.0069 4.05937 13.4945 5.179 13.5157 6.35523C13.5369 7.53147 13.09 8.66796 12.2733 9.51468C11.4566 10.3614 10.3369 10.849 9.1607 10.8702ZM4.1607 14.5602C1.7407 16.1802 1.7407 18.8202 4.1607 20.4302C6.9107 22.2702 11.4207 22.2702 14.1707 20.4302C16.5907 18.8102 16.5907 16.1702 14.1707 14.5602C11.4307 12.7302 6.9207 12.7302 4.1607 14.5602Z" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  {{ $t('navbar.dropdown.account-user.list.user_accounts') }}
                </div>
              </routerLink>

              <routerLink class="dropdown-item profile-menu-item d-flex" to="/logout">
                <div class="my-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M15.1005 7.56023C14.7905 3.96023 12.9405 2.49023 8.89049 2.49023H8.76049C4.29049 2.49023 2.50049 4.28023 2.50049 8.75023V15.2702C2.50049 19.7402 4.29049 21.5302 8.76049 21.5302H8.89049C12.9105 21.5302 14.7605 20.0802 15.0905 16.5402M9.00049 12.0002H20.3805M18.1505 8.65023L21.5005 12.0002L18.1505 15.3502" stroke="black" stroke-opacity="0.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  {{ $t('navbar.dropdown.account-user.list.logout') }}
                </div>
              </routerLink>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.link-text {
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.link-text:hover {
  text-decoration: underline;
}
.unread-notification {
  background-color: #f5f4f2;
}

.notification-date {
  color: #7b7a79
}

.orange-theme-text {
  color: #fc6d31;
}
::v-deep input:checked + .slider:before {
  background-color: #FC6B2D;
}
::v-deep input:checked + .slider {
  background-color: #FFF0EA;
  border: 1px solid rgba(220, 218, 213, 0.50);
}
</style>
